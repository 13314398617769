import { get } from '@/utils/http'

export function getCpqx (params) {
  return get({ url: '/service-insight/finance_dim/cpqx', params })
}
export function getCpzt (params) {
  return get({ url: '/service-insight/finance_dim/cpzt', params })
}
export function getFxfs (params) {
  return get({ url: '/service-insight/finance_dim/fxfs', params })
}
export function getFxjg (params) {
  return get({ url: '/service-insight/finance_dim/fxjg', params })
}
export function getTzfx (params) {
  return get({ url: '/service-insight/finance_dim/tzfx', params })
}
export function getTzmk (params) {
  return get({ url: '/service-insight/finance_dim/tzmk', params })
}
export function getXmszd (params) {
  return get({ url: '/service-insight/finance_dim/xmszd', params })
}
export function getYjnhsy (params) {
  return get({ url: '/service-insight/finance_dim/yjnhsy', params })
}
